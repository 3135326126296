<template>
    <div class="inner-pages homepage-4 agents list hp-6 full hd-white">
    <div id="wrapper">
        <section class="properties-list full featured portfolio blog">
            <div class="container">
               <b-card class="mt-3 mb-3 res-margin-top">
            <section ref="hello-world" class="hello-world pt-0">
              <div class="pro-wrapper">
                <div class="detail-wrapper-body">
                  <div class="listing-title-bar">
                    <div class="text-heading text-left">
                      <p class="font-weight-bold mb-0 mt-3">
                        {{ `${total} Search result${total > 1 ? 's' : ''}` }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    cod-pad
                    single
                    detail-wrapper
                    mr-2
                    mt-0
                    b-flex
                    justify-content-md-end
                    align-items-center
                  "
                >
                  <div
                    class="
                      wsset
                      input-group
                      border
                      rounded
                      input-group-lg
                      w-auto
                      mr-4
                    "
                  >
                    <label
                      class="
                        input-group-text
                        bg-transparent
                        border-0
                        text-uppercase
                        letter-spacing-093
                        pr-1
                        pl-3
                      "
                      for="inputGroupSelect01"
                      ><i class="fas fa-align-left fs-16 pr-2"></i>Per
                      Page:</label
                    >
                    <select
                      v-model="perPage"
                      style="top: -3px;"
                      class="
                        pset
                        p0
                        form-control
                        border-0
                        bg-transparent
                        shadow-none
                        p-0
                        selectpicker
                        sortby
                      "
                      data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                      id="inputGroupSelect01"
                      name="sortby"
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option :value="totalProperties">All</option>
                    </select>
                  </div>
                  <div
                    class="
                      input-group
                      border
                      rounded
                      input-group-lg
                      g
                      w-auto
                      mr-4
                    "
                  >
                    <label
                      class="
                        input-group-text
                        gset
                        bg-transparent
                        border-0
                        text-uppercase
                        letter-spacing-093
                        pr-1
                        pl-3
                      "
                      for="inputGroupSelect01"
                      ><i class="fas fa-align-left fs-16 pr-2"></i
                      >Sortby:</label
                    >
                    <select
                     style="top: -3px;"
                      class="
                        form-control
                        gset
                        
                        border-0
                        bg-transparent
                        shadow-none
                        p-0
                        selectpicker
                        sortby
                      "
                      data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3"
                      id="inputGroupSelect01"
                      name="sortby"
                      v-model="sorting"
                    >
                      <option
                        @click="addToQuery('sort', 'price|asc')"
                        value="price|asc"
                      >
                        Price(low to high)
                      </option>
                      <option
                        @click="addToQuery('sort', 'price|desc')"
                        value="price|desc"
                      >
                        Price(high to low)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </section>
          </b-card>
                <div class="row featured portfolio-items h-auto">

                        <buying-list-view @total="total = $event" :showPerPage="perPage" :sorting="sorting" :processing="processing" :viewings="myViewing" :offers="myOffers" :properties="allProperties"></buying-list-view>
                   
                </div>
            </div>
        </section>
        <a data-scroll href="#wrapper" class="go-up" style="display:inline"><i class="fa fa-angle-double-up" aria-hidden="true"></i></a>
    </div>
    </div>
</template>

<script>
import $ from "jquery";
const BuyingListView = () => import(/* webpackChunkName: "JEIiB63AOZ" */ "../components/buying-list-view");
import { mapGetters } from "vuex"
import _ from "lodash"
export default {
    middleware: [
        'authentication'
    ],
    data(){
        return{
            total: 0,
            perPage: "10",
            sorting: "price|desc",
            processing: true,
            keyword: "",
            shouldSearch: false,
            currentTabComponent: 'buyingListView',
            messages: 0
        }
    },
    computed:{
      totalProperties(){
        return this.myBuying.length
      },
        ...mapGetters({
            authUser: 'auth',
            myBuying: 'getMyBuying',
            myOffers: 'getMyOffers',
            myViewing: 'getMyViewing',
        }),
        allProperties(){
            if(this.shouldSearch){
                let matched = _.filter(this.myBuying, property => {
                    return (
                        property.city.toLowerCase().includes(this.keyword)
                        || property.country.toLowerCase().includes(this.keyword)
                        || property.houseNo.toLowerCase().includes(this.keyword)
                        || property.street.toLowerCase().includes(this.keyword)
                    )
                })
                console.log({matched})
                return matched
            }else{
                return this.myBuying
            }
        }
    },
    methods:{
        clearSearch(){
            this.shouldSearch = false
            this.keyword = ""
        }
    },
    components: {
        BuyingListView,
    },
    async mounted(){
        if(_.isEmpty(this.myBuying)){
            await this.$store.dispatch('getMyBuying')
            this.processing = false
        }
        else
            this.processing = false
        if(_.isEmpty(this.myOffers))
            await this.$store.dispatch('getMyOffers')
        if(_.isEmpty(this.myViewing))
            await this.$store.dispatch('getMyViewing')
        if(_.isEmpty(this.authUser)){
            await this.$store.dispatch('getUser')
        }
        $(".dropdown-filter").on('click', function() {
                $(".explore__form-checkbox-list").toggleClass("filter-block");

            });
    },
}
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>>
<style scoped src="../assets/css/bootstrap.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

@media (min-width: 300px) and (max-width: 700px) {
  .res-m-0{
    margin-left:0px !important;
  }
  .res-m15{
    margin-top:15px !important;
  }
  .res-margin-top{
    margin-top: 1.5em !important;
  }
  .res-ml0{
    margin-left: 0px !important;
  }
  .res-m-wm{
    margin-left: 0px !important;
    width: 100% !important;
  }

  .res-images-swiming{
        margin-left: 14px;
    width: 93% !important;
  }

  .res-icons-img{
    font-size: 12px !important;
    width: 12% !important;

  }
  .res-bb{
    margin-top: -70px !important;
        margin-left: 135px !important;
  }
  .wsset {
    margin-right: 0px !important;
    width: 100% !important;
  }
  .g {
    margin-right: 0px !important;
    width: 69% !important;
  }
  .gset{
    top: 0px;
    left: 92px !important;
    margin: 0px !important;
  }
  .lf22 {
    left: 22px !important;
  }
  .gset {
    width: 20px !important;
  }
  .p0 {
    padding: 0px !important;
  }
  .advance {
    padding-left: 9px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .b-flex{
    display: block !important;
  }
}


.b-flex{
  display: flex;
}

</style>
